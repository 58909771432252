@font-face {
    font-family: 'AppFontBold';
    src: url('../assets/fonts/EnzoWeb\ W03\ Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'AppFontLight';
    src: url('../assets/fonts/EnzoWeb\ W03\ Light.ttf') format('truetype');
}

